.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 以下追加 */
html {
  height: 100%;
}
body {
  height: 100%;
  background-color: #F8F8F8;
}

.section {
  padding-top: 20px;
}

.create_environment_button, .create_environment_button:focus {
  width: 90px;
  margin: 0 0 3px 10px;
  background-color: #2187F0;
  color: #fff;
  font-size: 0.6em;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0,0,0,.3)
}
.create_environment_button:hover {
  background-color: #1b77d3;
  color: #f4f4f4;
}

.create_environment_button svg {
  margin-right: 5px;
}

.complete_status {
  color: #208105;
}

.failed_status{
  color: #D13213;
}

.tm-button-default{
  line-height: 38px;
  border-radius: 500px;
  font-size: 0.8em;
  padding: 0 20px;
}